<template>
    <div class="user">
        <search-header :isSnShow="false"></search-header>
        <div class="container pt-3 pb-3 p-0">
            <div class="row p-0">
                <div class="col-2">
                    <user-left :currentActive="7"></user-left>
                </div>
                <div class="col-10 f-14">
                    <div class="row bg-white rounded ml-2">
                        <div class="p-3 border-bottom col-12 d-flex justify-content-between">
                            <h6>我的银行卡</h6>
                            <a @click="addCard" href="javascript:;">
                                <span>添加银行卡</span>
                            </a>
                        </div>
                        <div class="col-12 mt-4 mb-2" >
                            <div class="card border-0">
                                <div class="card-body p-0">
                                    <div class="table-responsive" >
                                        <table class="table table-centered border mb-0">
                                            <thead class="table-light">
                                            <tr>
                                                <th>银行</th>
                                                <th>卡号</th>
                                                <th>持卡者</th>
                                                <th>身份证号</th>
                                                <th>预留手机号</th>
                                                <th>操作</th>
                                            </tr>
                                            </thead>
                                            <tbody v-if="list.length > 0">
                                            <tr v-for="i,m in list" :key="m">
                                                <td>
                                                    <div class="mt-2">{{i.openBank}}</div>
                                                </td>
                                                <td>
                                                    <div class="mt-2">{{i.cardNumber}}</div>
                                                </td>
                                                <th>
                                                    <div class="mt-2">{{i.cardHolder}}</div>
                                                </th>
                                                <th>
                                                    <div class="mt-2">{{i.idNumber}}</div>
                                                </th>
                                                <td>
                                                    <div class="mt-2">{{i.mobile}}</div>
                                                </td>
                                                <td>
                                                    <div class="mt-1 mb-1 f-16">
                                                        <a href="javascript:void(0);" class="ml-2">
                                                            <b-icon icon="trash" @click="deleteBankcard(i.id,m)"></b-icon>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td colspan="6">
                                                        <div class="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                                                            <div class="text-gray-777">没有找到银行卡信息，您可以马上</div>
                                                            <div class="ml-3">
                                                                <b-button variant="success" @click="addCard">添加银行卡</b-button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <map-footer :isMapShow="false"></map-footer>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                list: []
            };
        },
        methods: {
            getList() {
                this.$http
                    .post(this.$api.user.getBankCard)
                    .then((res) => {
                        this.list = res.result;
                    });
            },
            addCard() {
                if (this.list.length > 10) {
                    this.$model.toast('已超过绑定上线')
                } else {
                    let u = this.$router.resolve({path: '/user/account/bankcard/add'});
                    window.open(u.href, '_blank');
                }
            },
            deleteBankcard(id,m) {
                this.$dialog.alert({
                    title: "温馨提示",
                    message: "是否删除绑定银行卡?",
                    showCancelButton: true
                }).then(() => {
                    this.$http
                    .delete(this.$api.user.deleteBankCard + id)
                    .then((res) => {
                        if (res.code == 1) {
                            this.list.splice(m, 1);
                        }
                    });
                })
            }

        },
        created() {
            this.getList();
        }
    };
</script>

<style scoped>
    @import url("~@/assets/css/user.css");
</style>
